<form [formGroup]='formGroup' (ngSubmit)='onSubmit()' class='flex flex-col gap-[4px] mb-[16px]'>
  @if (status$.value === 0) {
    <app-wallet-connect [direction]='direction' (directionChange)='directionChange($event)'
                        [disabled]='loading' [sameNetwork]='(neon.isSameNetwork$|async)!'></app-wallet-connect>
    <app-token-amount #tokenAmount formControlName='amount' [token]='token$' [direction]='direction$'
                      (maxEmit)='maxAmount($event)'>
      <app-token-select-button formControlName='token' [direction]='direction'></app-token-select-button>
    </app-token-amount>
  }
  <div class='transfer-form-actions'>
    @if (status$.value === 1) {
      <div class='view flex-box-col min-h-[250px]'>
        <div class='flex justify-center items-center h-[102px]'>
          @switch (transfer.pendingStatus$|async) {
            @case (pendingStatusRejected) {
              <img loading='lazy' src='/assets/icons/transfer-error.svg' alt='error' width='102px' height='102px' />
            }
            @default {
              <app-transfer-progress></app-transfer-progress>
            }
          }
        </div>
        <h1 class='title'>{{ title$|async }}</h1>
        <p class='message' [innerHTML]='message$|async'></p>
      </div>
      <div class='info flex-box-col'>
        @if (transfer.pendingStatus$.value !== pendingStatusRejected) {
          @if ((transfer.pendingStatus$.value) < pendingStatusConfirmed) {
            <div class='info-item flex-box-row'>
              <span class='label'>Time to <span class='text-white'>{{ transferToo }}</span></span>
              <span class='label'>~30 seconds</span>
            </div>
          }
          <div class='info-item flex-box-row'>
            <app-transfer-gas-fees [direction]='direction' [reward]='rewardFrom'></app-transfer-gas-fees>
          </div>
          <div class='info-item' [ngClass]='transfer.pendingStatus$.value >= pendingStatusConfirmed ? "mb-[16px]" : ""'>
            <app-token-receive [token]='token' [amount]='amountView' [reward]='rewardFrom'
                               [direction]='direction'></app-token-receive>
          </div>
        }
        @switch (transfer.pendingStatus$.value) {
          @case (pendingStatusConfirmed) {
            <div class='flex-box-col gap-[16px] w-full'>
              @if (trackInExplorer|async; as link) {
                <a class='button flex-box-row' [href]='link' target='_blank'>
                  Track in Explorer
                  <svg-icon src='/assets/icons/link-arrow.svg'></svg-icon>
                </a>
              }
              <button type='button' class='button flex-box-row' [class.primary]='!isSolana' (click)='retryTransfer()'>
                Initiate New Transfer
              </button>
              @if (isSolana) {
                <a class='ecosystem' [href]='ecosystemUrl' target='_blank'>
                  <span class='text'>Explore Neon<br /> ecosystem -></span>
                </a>
              }
            </div>
          }
          @case (pendingStatusRejected) {
            <div class='flex-box-col gap-[16px] w-full'>
              <button type='button' class='button primary flex-box-row' (click)='retryTransfer()'>
                Retry transfer
              </button>
            </div>
          }
        }
      </div>
    } @else {
      <div class='transfer-form-actions-pd'>
        <div class='overflow-hidden' [@collapse]='isWalletConnected$|async'>
          <div class='p-[4px]'>
            <app-neon-transaction-fee formControlName='rewardFrom' [direction]='direction' [token]='token'
                                      [amount]='amount' [reward]='rewardFrom' [error]='feeErrorText$'
                                      [loading]='transferFee.loading$'></app-neon-transaction-fee>
            <app-solana-priority-fee [reward]='rewardFrom' [direction]='direction' [amount]='amount'
                                     [loading]='transferFee.loading$'
                                     [error]='feeErrorText$' [errorDescription]='feeErrorDescription'></app-solana-priority-fee>
            <app-token-receive [token]='token' [amount]='amountView' [reward]='rewardFrom'
                               [direction]='direction' class='py-[16px] mb-[20px]'></app-token-receive>
          </div>
        </div>
        <div class='p-[4px]'>
          @switch (neon.isSameNetwork$|async) {
            @default {
              <button [disabled]='disabled||(loading|async)||(transferFee.loading$|async)'
                      class='button primary' type='submit' data-testid='transfer-button'>
                <span class='text relative'>
                  {{ transferButtonText$|async }}
                  @if (transferFee.loading$|async) {
                    <app-loader-small class='absolute top-[50%] left-[-20px] translate-y-[-50%]'
                                      color='#3F3A46'></app-loader-small>
                  }
                </span>
              </button>
            }
            @case (false) {
              <button class='button primary' type='button' (click)='chainSwitch()'>Switch to Neon EVM</button>
            }
          }
        </div>
      </div>
    }
  </div>
</form>
@if (status$.value === 0) {
  <div class='flex flex-col gap-[8px]'>
    <a class='addition-link' (click)="handleClick('how_to_use_click')"
       href='https://neonevm.org/docs/token_transferring/neonpass_usage#transfer-tokens-with-neonpass' target='_blank'>
      <svg-icon src='/assets/icons/info-link.svg'></svg-icon>
      <span class='text'>How to use NeonPass</span>
      <svg-icon src='/assets/icons/arrow-top-right.svg'></svg-icon>
    </a>
    <a class='addition-link'
       (click)="handleClick('how_to_add_click')"
       href='https://www.notion.so/neonfoundation/How-to-add-a-Third-Party-token-to-Neonpass-7439fafc89ca43d4867f90803776a74e'
       target='_blank'>
      <svg-icon src='/assets/icons/info-link.svg'></svg-icon>
      <span class='text'>How to add new tokens to NeonPass</span>
      <svg-icon src='/assets/icons/arrow-top-right.svg'></svg-icon>
    </a>
  </div>
}
