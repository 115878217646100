<button type='button' (click)='open()' class='menu-button' [class.active]='show' aria-label='Header menu'>
  <svg-icon src='/assets/icons/menu.svg'></svg-icon>
</button>
@if (show) {
  <div class='menu' [@enterLeave]='showAnimation'>
    <a class='menu-item' target='_blank' href='https://neonevm.org/docs/faq/neonpass'>
      <img loading='lazy' src='/assets/icons/menu-faq.svg' alt='faq' width='16' height='16' />
      <span class='text'>FAQ</span>
    </a>
    <a class='menu-item' target='_blank' href='https://neonevm.org/docs/token_transferring/neonpass_usage'>
      <img loading='lazy' src='/assets/icons/menu-docs.svg' alt='docs' width='16' height='16' />
      <span class='text'>Docs</span>
    </a>
    <a class='menu-item' target='_blank' href='https://x.com/Neon_EVM'>
      <img loading='lazy' src='/assets/icons/menu-x.svg' alt='x' width='16' height='16' />
      <span class='text'>Twitter</span>
    </a>
    <a class='menu-item' target='_blank' href='http://discord.com/invite/neonevm'>
      <img loading='lazy' src='/assets/icons/menu-discord.svg' alt='discord' width='16' height='16' />
      <span class='text'>Discord Community</span>
    </a>
    <a class='menu-item' target='_blank' href='https://neonevm.org'>
      <img loading='lazy' src='/assets/icons/menu-home.svg' alt='home' width='16' height='16' />
      <span class='text'>About Neon</span>
    </a>
    <div class='discord-button-wrapper'>
      <a href='http://discord.com/invite/neonevm' class='discord-button' target='_blank'>
        <img loading='lazy' src='/assets/icons/discord.svg' alt='discord' width='16' height='16' />
        <span>Support</span>
      </a>
    </div>
  </div>
}
